<template>
  <mobile-screen :header="true" screen-class="edit-level gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_plan',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ level && level.data ? level.data.name : "" }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <template v-if="level && level.data">
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{ displayLabelName("plan", "level", "name") }}
                <span>*</span></label
              >
              <p class="text-label">
                {{
                  level.data.name || displayLabelName("plan", "level", "name")
                }}
              </p>
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="level.data.all_parents && level.data.all_parents.length"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{ displayLabelName("plan", "level", "name-and-location") }}
                <span>*</span></label
              >
              <p class="text-label">
                {{ displayParentNames(level.data.all_parents) }}
              </p>
            </div>
          </div>
        </li>
      </ul>
      <ul
        class="clebex-item-section"
        v-if="level.data.timezone_id || level.data.inherited_timezone_id"
      >
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("plan", "level", "timezone")
              }}</label>
              <p class="text-label">
                {{
                  displayTimezone(
                    level.data.timezone_id || level.data.inherited_timezone_id
                  )
                }}
              </p>
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section" v-if="level.data.phone">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("plan", "level", "telephone")
              }}</label>
              <p class="text-label">
                {{
                  level.data.phone ||
                    displayLabelName("plan", "level", "telephone")
                }}
              </p>
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section" v-if="level.data.google_maps_link">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("plan", "level", "google-maps-link")
              }}</label>
              <p class="text-label">
                {{
                  level.data.google_maps_link ||
                    displayLabelName("plan", "level", "google-maps-link")
                }}
              </p>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item" v-if="level.data.address_line_1">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("plan", "level", "address1")
              }}</label>
              <p class="text-label">
                {{
                  level.data.address_line_1 ||
                    displayLabelName("plan", "level", "address1")
                }}
              </p>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item" v-if="level.data.address_line_2">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("plan", "level", "address2")
              }}</label>
              <p class="text-label">
                {{
                  level.data.address_line_2 ||
                    displayLabelName("plan", "level", "address2")
                }}
              </p>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item" v-if="level.data.city">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("plan", "level", "city")
              }}</label>
              <p class="text-label">
                {{
                  level.data.city || displayLabelName("plan", "level", "city")
                }}
              </p>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item" v-if="level.data['zip']">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("plan", "level", "zip-code")
              }}</label>
              <p class="text-label">
                {{
                  level.data["zip"] ||
                    displayLabelName("plan", "level", "zip-code")
                }}
              </p>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item" v-if="level.data.state">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("plan", "level", "state")
              }}</label>
              <p class="text-label">
                {{
                  level.data.state || displayLabelName("plan", "level", "state")
                }}
              </p>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item" v-if="level.data.country_id">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("plan", "level", "country")
              }}</label>
              <p class="text-label">
                {{ displayCountryName(level.data.country_id) }}
              </p>
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section" v-if="level.data.map_id">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{ displayLabelName("plan", "level", "5d-maps") }}
              </label>
              <p class="text-label">
                {{ displayMapName(level.data.map_id) }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { mapActions, mapState } from "vuex";
import timezonesMixin from "@/services/mixins/timezones/timezones-mixin";
import countriesMixin from "@/services/mixins/countries/countries-mixin";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "PlanLevelDetail",
  data() {
    return {
      level: null
    };
  },
  mounted() {
    this.getCountries();
    this.getMaps();
    this.getLevel(this.$route.params.level_id);
  },
  computed: {
    ...mapState("level", ["maps"]),
    ...mapState("country", ["countries"]),
    selectedCountryId() {
      if (this.level && this.level.data && this.level.data.country_id) {
        return this.level.data.country_id;
      }

      return null;
    },
    selectedTimezoneId() {
      if (this.selectedTimezone) {
        return this.selectedTimezone;
      }

      if (this.level && this.level.data && this.level.data.timezone_id) {
        return this.level.data.timezone_id;
      }

      return null;
    },
    mapId() {
      return (
        (this.selectedMap && this.selectedMap.id) ||
        (this.level && this.level.data && this.level.data.map_id)
      );
    },
    routeParam() {
      const { level_id } = this.$route.params;
      if (level_id) return level_id;
      return null;
    }
  },
  watch: {
    routeParam() {
      if (this.routeParam) this.getLevel(this.routeParam);
    }
  },
  methods: {
    ...mapActions("level", ["getMaps"]),
    ...mapActions("country", ["getCountries"]),
    displayMapName(id) {
      if (this.maps && this.maps.data && this.maps.data.length) {
        const filtered = this.maps.data.filter(item => item.id === id);
        if (filtered && filtered.length) {
          return filtered[0].name;
        }
        return "";
      }
      return "";
    },
    getLevel(levelId) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.levels}/${levelId}`, {
          params: {
            includes: ["all_parents"]
          }
        })
        .then(response => {
          this.level = response.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    displayParentNames(parents) {
      const filteredParents = parents.filter(item => item.name);
      return filteredParents.map(parent => parent.name).join(", ");
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [timezonesMixin, countriesMixin]
};
</script>
